import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Result, Spin, Modal } from 'antd';
import axios from 'axios';
import { Route, Switch } from 'react-router-dom';
import { API_REFFERENCE, API_URL, isLOCALHOST } from './config';
import HeaderTemplate from './components/HeaderTemplate';
import FooterTemplate from './components/FooterTemplate';
import SocialSharingPage from './components/SocialSharingPage';
import NegativeFeedbackPage from './components/NegativeFeedbackPage';
import NearestLocationNotFound from './components/NearestLocationNotFound';
import GiveMoreDetails from './components/GiveMoreDetails';
import DefaultLogo from './assets/images/Localyser_Brand_Icon.png';
import CustomerRequest from './components/CustomerRequest/index';
import ReviewImage from './components/ReviewImage';
import Coupon from './components/Coupon';

import { BRAND_ID, CLIENT_ID } from './constants/clientID';
import {FEEDBACK_TYPES} from "./constants/feedbackTypes";

import 'antd/dist/antd.css';
import { FeedbackForm, Kiosk } from '@localyser/localyser-feedback-form';
import style from './style.module.css';

isLOCALHOST && (() => {
  // create a new instance of `MutationObserver` named `observer`,
  // passing it a callback function
  const observer = new MutationObserver((mutations) => {
    const elem = mutations.pop().addedNodes[0];

    if(elem && elem.tagName === 'IFRAME') {
      if (!elem.classList.contains('html2canvas-container')) {
        console.log("ANNOYING IFRAME DETECTED, killing it!");
        elem.remove();
      }
    }
  });

  // call `observe()`, passing it the element to observe, and the options object
  observer.observe(document.body, {
    subtree: false,
    childList: true,
  });
})();

const App = () => {
  const [clientSettings, setClientSettings] = useState(null);
  const [language, setLanguage] = useState('en');
  const [rateValue, setRateValue] = useState(0);
  const [templateSettings, setTemplateSettings] = useState('');
  const [pageInfo, setPageInfo] = useState('');
  const [locationValue, setLocationValue] = useState(undefined);
  const [userFeedback, setUserFeedback] = useState({});
  const [leftFeedback, setLeftFeedback] = useState();
  const [showSocialSharingPage, setSocialSharingPage] = useState(false);
  const [showNearestLocationNotFound, setNearestLocationNotFound] = useState(
    false
  );
  const [brandLocations, setBrandLocations] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showDetailsPage, setShowDetailsPage] = useState(false);
  const [showReviewImage, setShowReviewImage] = useState(false);
  const [feedbackId, setFeedbackId] = useState(undefined);
  const [statistic_id, setStatisticId] = useState('');
  const [currentLocationLogo, setCurrentLocationLogo] = useState('');
  const [informationRequestId, setInformationRequestId] = useState(null);
  const [requestError, setRequestError] = useState({});
  const [
    redirectToLocationByCreatedAt,
    setRedirectToLocationByCreatedAt,
  ] = useState(false);
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [isInsightsReportEnabled, setIsInsightsReportEnabled] = useState(false);
  const [showLocationList, setShowLocationList] = useState(false);
  const [coupon, setCoupon] = useState({});

  const location = useLocation();
  const [feedbackType, setFeedbackType] = useState('')

  const getUrlParams = () => {
    const pathname = window.location.pathname.split('/');
    let locationID = pathname[1];

    if (
      pathname &&
      (pathname.includes('brand') ||
        pathname.includes('details') ||
        pathname.includes('i'))
    ) {
      return (locationID = pathname[2]);
    }
    return locationID;
  };

  const sendStatisticDataOnOpenLandingPage = useCallback(async (locationId) => {
    const statisticsData = {
      location_id: locationId,
    };
    try {
      const response = await axios.post(
        `${API_URL}v2/api/lendingPageStatistic`,
        statisticsData
      );
      setStatisticId(response.data.id);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const sendStatisticDataOnSharingFeedback = async (service) => {
    const statisticsData = {
      statistic_id: statistic_id,
      service_name: service,
      review: leftFeedback?.id,
    };

    try {
      await axios.post(
        `${API_URL}v2/api/lendingPageStatistic/sharing`,
        statisticsData
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getTemplateSettingsForLocation = useCallback(
    async (locationID) => {
      setLocationValue(+locationID);
      const isHash = window.location.hash;
      const reviewType = isHash ? isHash.split('#')[1] : 'general';

      setFeedbackType(reviewType)
      setShowSpinner(true);

      try {
        const response = await axios.get(
          `${API_URL}v2/api/lendingPage/location/page/${locationID}`
        );

        const { generalLogoUrl, deliveryLogoUrl, brandPhotoUrl } = response.data.pageInfo

        setPageInfo(response.data.pageInfo);
        setShowWarningMessage(
          response.data.pageInfo.companyStatus !== 'Active'
        );

        if (reviewType) {
          reviewType === 'general'
            ? setCurrentLocationLogo(generalLogoUrl || brandPhotoUrl)
            : setCurrentLocationLogo(deliveryLogoUrl || brandPhotoUrl);
        }

        const locationTitle = response.data.pageInfo.title;
        const locationId = response.data.pageInfo.locationId;
        const title =
          locationTitle && locationId && `${locationTitle} - ${locationId} - `;
        document.title = title
          ? `${title} Leave us a review`
          : 'Leave us a review';
        const settings = JSON.parse(response.data.pageSettings.settings);
        const clientSettings = response.data.clientSettings;
        const isInsightsEnabled = response.data.isInsightsReportEnabled;

        setClientSettings(clientSettings);
        setTemplateSettings(settings[reviewType]);
        setLanguage(clientSettings.defaultLanguage);
        setIsInsightsReportEnabled(isInsightsEnabled);
        setShowSpinner(false);
        await sendStatisticDataOnOpenLandingPage(locationID);
      } catch (error) {
        setShowSpinner(false);
        const status =
          error.request && error.request.status
            ? error.request.status
            : 'no status';
        const errorText =
          error.request && error.request.response
            ? error.request.response
            : 'Sorry, the page you visited does not exist.';
        setRequestError({ status, errorText });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sendStatisticDataOnOpenLandingPage]
  );

  const getLocationIdByRequestId = useCallback(
    async (requestId) => {
      try {
        const res = await axios.get(
          `${API_URL}v2/api/lendingPage/location/byRequestId/${requestId}`
        );

        await getTemplateSettingsForLocation(res?.data?.id);
      } catch (error) {
        setShowSpinner(false);
        const status =
          error.request && error.request.status
            ? error.request.status
            : 'no status';
        const errorText =
          error.request && error.request.response
            ? error.request.response
            : 'Sorry, the page you visited does not exist.';
        setRequestError({ status, errorText });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [informationRequestId]
  );

  useEffect(() => {
    if (redirectToLocationByCreatedAt && brandLocations?.locations.length) {
      window.location = `${API_REFFERENCE}${brandLocations.locations[0].id}/br`;
    }
  }, [redirectToLocationByCreatedAt, brandLocations]);

  const getTemplateSettingsForBrand = useCallback(
    (brandId) => {
      setShowSpinner(true);
      axios
        .get(`${API_URL}v2/api/lendingPage/brand/page/${brandId}`)
        .then((response) => {
          const brandLocations = response.data;

          setCurrentLocationLogo(response.data.photoUrl)
          setBrandLocations(brandLocations);

          const locations = brandLocations.locations.map((location) => {
            return {
              ...location,
              id: location.id,
              geoInfo: {
                lat: location.geoInfo ? location.geoInfo.latitude : '',
                lon: location.geoInfo ? location.geoInfo.longitude : '',
              },
            };
          });
          setShowSpinner(false);
          return locations;
        })
        .then((locations) => {
          const locationsWithCoordinats = locations.filter(
            (location) => location.geoInfo.lat
          );
          if (locationsWithCoordinats.length === 0) {
            setNearestLocationNotFound(true);
          } else {
            const location = window.navigator && window.navigator.geolocation;
            let distancesBetween = null;
            if (location) {
              location.getCurrentPosition(
                (position) => {
                  let lat = position.coords.latitude;
                  let lon = position.coords.longitude;
                  distancesBetween = locationsWithCoordinats.map(
                    (location) =>
                      location.geoInfo.lat && {
                        ...location,
                        distance: distanceBetweenPoints(
                          lat,
                          lon,
                          +location.geoInfo.lat,
                          +location.geoInfo.lon
                        ),
                        id: location.id,
                      }
                  );
                  const nearestLocation = findNearestLocation(distancesBetween);

                  goToNearestLocation(nearestLocation);

                  return nearestLocation;
                },
                () => {
                  setRedirectToLocationByCreatedAt(true);
                }
              );
            }
          }
        });
    },
    [setRedirectToLocationByCreatedAt]
  );

  const getTemplateSettingsForFeedback = useCallback(
    async (feedbackId) => {
      setFeedbackId(+feedbackId);
      setShowSpinner(true);
      const isHash = window.location.hash;
      const reviewType = isHash ? isHash.split('#')[1] : 'general';

      try {
        const response = await axios.get(
          `${API_URL}v2/api/lendingPageSettings/feedback/${feedbackId}`
        );

        const pageInfo = {
          reviewRating: response.data.reviewRating,
          title: response.data.title,
          locationId: response.data.locationId,
          locationAddress: response.data.locationAddress,
        };
        setPageInfo(pageInfo);
        const locationTitle = response.data.title;
        const locationId = response.data.locationId;
        const title =
          locationTitle && locationId && `${locationTitle}-${locationId} - `;
        document.title = title
          ? `${title}Leave us a review`
          : 'Leave us a review';
        const settings = JSON.parse(response.data.settings);
        setTemplateSettings(settings[reviewType]);
        setShowSpinner(false);
      } catch (error) {
        setShowSpinner(false);
        console.log(error);
      }

      setShowDetailsPage(true);
    },
    []
  );

  const getTemplateSettings = useCallback(async () => {
    const locationID = getUrlParams();
    const pathName = window.location.pathname.split('/');
    pathName.includes('brand') && getTemplateSettingsForBrand(locationID);
    pathName.includes('br') && setShowLocationList(true);
    pathName.includes('details') &&
      (await getTemplateSettingsForFeedback(locationID));
    pathName.includes('img') && setShowReviewImage(true); // check if user come from brand landing page and show select location
    !pathName.includes('brand') &&
      !pathName.includes('details') &&
      !pathName.includes('i') &&
      !pathName.includes('img') &&
      (await getTemplateSettingsForLocation(locationID));
    if (pathName.includes('i') && !pathName.includes('details')) {
      setInformationRequestId(locationID);
      await getLocationIdByRequestId(locationID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getTemplateSettingsForBrand,
    getTemplateSettingsForLocation,
    getTemplateSettingsForFeedback,
    setShowReviewImage,
  ]);

  const getCouponInforamtion = useCallback(
    async (couponCode) => {
      setShowSpinner(true);

      try {
        const response = await axios.get(
          `${API_URL}v2/api/lendingPageSettings/coupons/${couponCode}`
        );
        const coupon = {
          companyName: response.data.companyName,
          code: response.data.code,
          offerName: response.data.offerName,
          expireDate: response.data.expireDate
        }
        setCoupon(coupon);
        setShowSpinner(false);
      } catch (error) {
        setShowSpinner(false);
        const status =
          error.request && error.request.status
            ? error.request.status
            : 'no status';
        const errorText =
          error.request && error.request.response
            ? error.request.response
            : 'Sorry, the page you visited does not exist.';
        setRequestError({ status, errorText });
      }

      setShowDetailsPage(true);
    },
    []
  );

  useEffect(() => {
    const path = location.pathname;
    const isCouponPage = path.includes('/c/');
    let code;
    if (isCouponPage) {
      const couponUrlPart = path.split('/c/')[1];
      code = couponUrlPart.split('-')[1];
    }

    if (!locationValue && !code) {
      getTemplateSettings();
    } else if (code) {
      getCouponInforamtion(code);
    }
  }, [locationValue, getTemplateSettings]);

  const goToNearestLocation = (nearestLocation) => {
    const url = `${API_REFFERENCE}${nearestLocation.id}/br`;
    window.location = url;
  };

  const findNearestLocation = (distancesBetween) => {
    let nearestLocation = { distance: 0, id: 0 };
    distancesBetween.forEach((location, index) => {
      if (index === 0) {
        nearestLocation = { ...location };
      } else {
        if (location.distance < nearestLocation.distance) {
          nearestLocation = { ...location };
        }
      }
    });
    return nearestLocation;
  };

  const distanceBetweenPoints = (lat1, lon1, lat2, lon2) => {
    var p = 0.017453292519943295; // Math.PI / 180
    var c = Math.cos;
    var a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  };
  const isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor);

  // mobile app JavaScriptChannel integration
  if (typeof messageHandler !== 'undefined') {
    const form = document.getElementsByTagName('form');
    Array.from(form).forEach((el) => {
      el.addEventListener('touchstart', (event) => {
        const target = event.target;
        if ((target.tagName === 'INPUT' && target.type === 'text') || target.tagName === 'TEXTAREA') {
          if (!target.hasEventListener) { // Check if listener was not added to prevent event flood
            target.addEventListener('input', () => {
              window.sendMessageToFlutter('TEXT_TYPING');
            });
            target.hasEventListener = true; // Set flag to indicate listener was added to prevent event flood
          }
        }
        window.sendMessageToFlutter('FORM_TAPPED');
      });
    });

    // Adding event listener to phone with country codes input
    const phoneInputDiv = document.querySelector('.react-tel-input');
    if (phoneInputDiv) {
      const phoneInput = phoneInputDiv.querySelector('input[type="tel"]');
      if (phoneInput) {
        if (!phoneInput.hasEventListener) {
          phoneInput.addEventListener('input', () => {
            window.sendMessageToFlutter('TEXT_TYPING');
          });
          phoneInput.hasEventListener = true;
        }
      }
    }
  }

  const postUserFeedback = async (userFeedback) => {
    setRateValue(userFeedback.customerInformation.rating);
    setUserFeedback(userFeedback);

    const date = new Date();
    const year = date.getFullYear();
    let month = '';
    if (`${date.getMonth() + 1}`.length === 1) {
      month = '0' + (date.getMonth() + 1);
    } else {
      month = date.getMonth() + 1;
    }
    let day = '';
    if (`${date.getDate()}`.length === 1) {
      day = '0' + date.getDate();
    } else {
      day = date.getDate();
    }

    const currentDay = `${year}-${month}-${day}`;
    const isHash = window.location.hash;
    const reviewType = isHash ? isHash.split('#')[1] : 'general';

    const postedFeedback = {
      location: locationValue,
      name: userFeedback.customerInformation?.name?.value || 'no data',
      email: userFeedback.customerInformation?.email?.value,
      mobile: userFeedback.customerInformation?.mobile?.value,
      reviewText: userFeedback.customerInformation?.feedback,
      ratingValue: userFeedback.customerInformation.rating,
      date: currentDay, //'Review date in format yyyy-mm-dd',
      reviewType: reviewType,
      joinNews:
        userFeedback.customerInformation?.email?.value &&
        userFeedback.customerInformation?.joinNews,
      additionalInfo: userFeedback,
    };

    try {
      const response = await axios.post(
        `${API_URL}v2/api/lendingPage/feedback`,
        postedFeedback
      );

      setSocialSharingPage(true);
      setLeftFeedback(response.data);
    } catch (error) {
      console.log(error);
    }

    // mobile app JavaScriptChannel integration
    if (typeof messageHandler !== 'undefined') {
      window.sendMessageToFlutter('FORM_SUBMITTED');
    }

    if (isSafari && sharingSourcesWithoutDefaultUrl.length === 1) {
      await sharingLink(sharingSourcesWithoutDefaultUrl[0].title);
    }

    setSocialSharingPage(true);
  };

  const onSelectLanguage = (value) => {
    setLanguage(value);
  };

  const onSelectLocation = (value) => {
    const url = `${API_REFFERENCE}${value}/br`;
    window.location = url;
    setLocationValue(value);
  };

  const openSheringLink = (url) => {
    window.open(url);
  };

  const sharingLink = async (source) => {
    const url = changeUrl(source);
    await sendStatisticDataOnSharingFeedback(source);
    openSheringLink(url);
  };

  const changeUrl = (source) => {
    let url = pageInfo[source];
    switch (source) {
      case 'tripadvisor':
        url = url.replace(/Attraction_Review/, 'UserReviewEdit');
        url = url.replace(/Restaurant_Review/, 'UserReviewEdit');
        return url;
      case 'facebook':
        const desctopUrl = 'https://www.facebook.com/';
        const appUrl = 'fb://';
        const changedUrl =
          window.innerWidth < 425 ? url.replace(desctopUrl, appUrl) : url;
        return `${changedUrl}reviews/`;
      case 'google':
        return `https://search.google.com/local/writereview?placeid=${pageInfo.googlePlaceId}`;
      case 'zomato':
        return `${url}#my-reviews-container`;
      default:
        return url;
    }
  };

  const onGoToFeedbackpage = () => {
    setRateValue(0);
    setSocialSharingPage(false);
  };

  const sendNegativeFeddbackDetails = async (details) => {
    const negativeFeddbackDetails = {
      feedback_id: leftFeedback.id,
      text: details,
    };

    try {
      await axios.post(
        `${API_URL}v2/api/lendingPage/feedback/negative`,
        negativeFeddbackDetails
      );
    } catch (error) {
      console.log(error);
    }
  };

  const sendUpdatedReviewText = async (reviewText) => {
    const updatedReviewText = {
      id: feedbackId,
      text: reviewText,
    };

    try {
      await axios.patch(
        `${API_URL}v2/api/lendingPage/feedback`,
        updatedReviewText
      );
    } catch (error) {
      console.log(error);
    }
  };

  const {
    lables,
    sharingSources,
    enableSharingWithRating,
    backgroundColor,
    fontColor,
    footerUrl,
    skipStepsWithRating
  } = templateSettings;

  const companyName = pageInfo
    ? pageInfo.title + ' - ' + pageInfo.locationId
    : 'No data';
  const companyNameBrandLandingPage = brandLocations
    ? brandLocations.title
    : 'No data';

  const languagesOptions = clientSettings
    ? clientSettings.responseLanguages
    : ['en'];
  const submitButton = 'Submit';
  const sharingSourcesWithoutDefaultUrl =
    sharingSources &&
    sharingSources.filter((source) => pageInfo[source.title] && source.display);
  const logoUrl = currentLocationLogo ? `${currentLocationLogo}?rand=${new Date().getTime()}` : DefaultLogo;
  const footerCompanyName =
    lables &&
      lables.footerCompanyName &&
      lables.footerCompanyName[language] !== ' '
      ? lables.footerCompanyName[language]
      : pageInfo
        ? pageInfo.title
        : '';

  const listOfLocations = showLocationList && pageInfo.avaliableLocations?.length > 1 ? pageInfo.avaliableLocations : []

  const getPageBackground = (clientId, feedbackType, brandId) => {
    if (clientId === CLIENT_ID.shelby && feedbackType === FEEDBACK_TYPES.kiosk && brandId === BRAND_ID.abbasSmoothies) {
      return style.abbassWrapper;
    } else if (clientId === CLIENT_ID.shelby && feedbackType === FEEDBACK_TYPES.kiosk && brandId !== BRAND_ID.abbasSmoothies) {
      return style.shelbyWrapper;
    } else {
      return '';
    }
  }

  return (
    <div className='App'>
      <Switch>
        <Route path="/c/:couponId" exact={true}
          render={(props) =>
            requestError.status && requestError.errorText ? (
              <Result
                status={requestError.status !== 'no status' ? '403' : '404'}
                title={requestError.status !== 'no status' ? requestError.status : 'Error'}
                subTitle={` ${requestError.errorText}.`}
              />
            ) : showSpinner ? (
              <div className={style.spinnerWrapper}>
                <Spin className={style.spinnerStyle} />
              </div>
            ) : (
              <Coupon {...props} coupon={coupon} />
            )
          }
        />
        <Route path="/" render={() => <>
          {showWarningMessage && (
            <Modal visible={true} closable={false} centered={true} footer={null}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: language === 'en' ? 'row' : 'row-reverse',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <svg
                  viewBox='64 64 896 896'
                  focusable='false'
                  data-icon='file-unknown'
                  width='3em'
                  height='3em'
                  fill='currentColor'
                  aria-hidden='true'
                >
                  <path d='M854.6 288.7L639.4 73.4c-6-6-14.2-9.4-22.7-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.6-9.4-22.6zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494zM402 549c0 5.4 4.4 9.5 9.8 9.5h32.4c5.4 0 9.8-4.2 9.8-9.4 0-28.2 25.8-51.6 58-51.6s58 23.4 58 51.5c0 25.3-21 47.2-49.3 50.9-19.3 2.8-34.5 20.3-34.7 40.1v32c0 5.5 4.5 10 10 10h32c5.5 0 10-4.5 10-10v-12.2c0-6 4-11.5 9.7-13.3 44.6-14.4 75-54 74.3-98.9-.8-55.5-49.2-100.8-108.5-101.6-61.4-.7-111.5 45.6-111.5 103zm78 195a32 32 0 1064 0 32 32 0 10-64 0z' />
                </svg>
                <p style={{ marginBottom: '0' }}>
                  {language === 'en'
                    ? 'The page you are trying to reach is no longer available.'
                    : '.الصفحة التي تحاول الوصول إليها غير متوفرة'}
                </p>
              </div>
            </Modal>
          )}
          {informationRequestId && (
            <CustomerRequest
              reviewId={informationRequestId}
              languagesOptions={languagesOptions}
              lables={lables}
            />
          )}
          {showReviewImage && <ReviewImage />} {/* review image link */}
          {!informationRequestId && !showReviewImage && (
            <div className={`${style.templateWrapper} ${getPageBackground(pageInfo.client, feedbackType, pageInfo.brand)}`}>
              {!requestError.status && !requestError.errorText && (
                <HeaderTemplate
                  language={language}
                  onSelectLanguage={onSelectLanguage}
                  pageTitle={
                    lables ? templateSettings.lables.pageTitle[language] : ''
                  }
                  languagesOptions={languagesOptions}
                  showDetailsPage={showDetailsPage}
                  feedbackType={feedbackType}
                />
              )}
              {showSpinner && (
                <div className={style.spinnerWrapper}>
                  <Spin className={style.spinnerStyle} />
                </div>
              )}
              {requestError.status && requestError.errorText && (
                <Result
                  status={requestError.status !== 'no status' ? '403' : '404'}
                  title={
                    requestError.status !== 'no status'
                      ? requestError.status
                      : 'Error'
                  }
                  subTitle={` ${requestError.errorText}.`}
                />
              )}
              {templateSettings && !showSocialSharingPage && !showSpinner && (
                <div id="feedback-form-wrap">
                  {feedbackType === FEEDBACK_TYPES.kiosk ? (
                    <Kiosk
                      clientId={pageInfo.client}
                      brandId={pageInfo.brand}
                      formData={templateSettings}
                      language={language}
                      logoUrl={logoUrl}
                      companyName={companyName || pageInfo.locationId}
                      countryName={pageInfo.country}
                      countryCode={pageInfo.countryCode}
                      locationId={pageInfo.id}
                      listOfLocations={listOfLocations}
                      fetchLocationData={getTemplateSettingsForLocation}
                      feedbackType={feedbackType}
                      rateIcons={pageInfo.rateIcons}
                      isInsightsEnabled={isInsightsReportEnabled}
                      onSubmit={postUserFeedback}
                    />
                  ) : (
                    <FeedbackForm
                      clientId={pageInfo.client}
                      formData={templateSettings}
                      language={language}
                      logoUrl={logoUrl}
                      companyName={companyName || pageInfo.locationId}
                      companyAddress={
                        lables.companyAddress[language]
                          ? lables.companyAddress[language]
                          : pageInfo.locationAddress
                      }
                      countryName={pageInfo.country}
                      countryCode={pageInfo.countryCode}
                      locationId={pageInfo.id}
                      listOfLocations={listOfLocations}
                      fetchLocationData={getTemplateSettingsForLocation}
                      feedbackType={feedbackType}
                      isInsightsEnabled={isInsightsReportEnabled}
                      onSubmit={postUserFeedback}
                    />
                  )}
                </div>
              )}
              {showDetailsPage && lables && (
                <GiveMoreDetails
                  rateValue={pageInfo.reviewRating}
                  companyName={
                    lables.companyName[language]
                      ? lables.companyName[language]
                      : pageInfo.locationId
                  }
                  companyAdress={
                    lables.companyAddress[language]
                      ? lables.companyAddress[language]
                      : pageInfo.locationAddress
                  }
                  logoUrl={logoUrl}
                  sendUpdatedReviewText={sendUpdatedReviewText}
                  backgroundColor={backgroundColor}
                  fontColor={fontColor}
                />
              )}
              {showNearestLocationNotFound && (
                <NearestLocationNotFound
                  logoUrl={logoUrl}
                  locationValue={locationValue}
                  locationsOptions={brandLocations.locations}
                  dontFoundBrandMessage={
                    lables &&
                    lables.dontFoundBrandMessage &&
                    lables.dontFoundBrandMessage[language]
                  }
                  onSelectLocation={onSelectLocation}
                  backgroundColor={backgroundColor}
                  fontColor={fontColor}
                />
              )}
              {showSocialSharingPage && rateValue >= enableSharingWithRating ? (
                <SocialSharingPage
                  sharingSources={sharingSourcesWithoutDefaultUrl}
                  shareQuestion={lables.shareQuestion[language]}
                  shareExploration={
                    lables.shareExploration && lables.shareExploration[language]
                  }
                  gladMessage={lables.gladMessage[language]}
                  thanksForFeedback={lables.thanksForSupport[language]}
                  redirectTomessage={lables.redirectTomessage[language]}
                  redirectCountdown={lables.redirectCountdown[language]}
                  feedbackDetectedСopied={lables.feedbackDetectedСopied[language]}
                  feedbackNotDetected={lables.feedbackNotDetected[language]}
                  clipboardText={userFeedback.customerInformation?.feedback}
                  sharingLink={sharingLink}
                  shareButton={lables.shareButton[language]}
                  backgroundColor={backgroundColor}
                  fontColor={fontColor}
                  enableSharingWithRating={enableSharingWithRating}
                  oneSource={feedbackType === FEEDBACK_TYPES.kiosk ? false : sharingSourcesWithoutDefaultUrl.length === 1}
                  feedbackType={feedbackType}
                />
              ) : showSocialSharingPage &&
                rateValue &&
                rateValue < enableSharingWithRating ? (
                <NegativeFeedbackPage
                  // sendNegativeFeddbackDetails={sendNegativeFeddbackDetails}
                  onGoToFeedbackpage={onGoToFeedbackpage}
                  companyName={
                    lables.companyName[language]
                      ? lables.companyName[language]
                      : pageInfo.locationId
                  }
                  companyAdress={
                    lables.companyAddress[language]
                      ? lables.companyAddress[language]
                      : pageInfo.locationAddress
                  }
                  // userDetailsFeedbackTitle={
                  //   lables && lables.userDetailsFeedbackTitle
                  //     ? lables.userDetailsFeedbackTitle[language]
                  //     : 'Please provide more details about your experience, thank you.'
                  // }
                  // userFeedbackPlaceholder={
                  //   lables && lables.userFeedbackPlaceholder[language]
                  // }
                  // userFeedbackPlaceholder = {lables && lables.userFeedbackPlaceholder[language]}
                  // submitButtonTitle={
                  //   lables && lables.giveMoreDetailsButtonWithoutFeedback
                  //     ? lables.giveMoreDetailsButtonWithoutFeedback[language]
                  //     : 'Submit'
                  // }
                  returnToFeedbackButton={lables.returnToFeedbackButton[language]}
                  backgroundColor={backgroundColor}
                  thanksForFeedback={lables && lables.thanksForFeedback[language]}
                  sorryMessage={
                    lables.sorryMessage && lables.sorryMessage[language]
                  }
                  sorryMessageWithoutContacts={
                    lables.sorryMessageWithoutContacts &&
                    lables.sorryMessageWithoutContacts[language]
                  }
                  // giveMoreDetailsButton={
                  //   lables && lables.giveMoreDetailsButton[language]
                  // }
                  // submitButton={
                  //   lables &&
                  //     lables.giveMoreDetailsButton &&
                  //     lables.giveMoreDetailsButton[language]
                  //     ? lables.giveMoreDetailsButton[language]
                  //     : submitButton
                  // }
                  fontColor={fontColor}
                  // submitValidationMessage={
                  //   lables.submitValidationMessage &&
                  //   lables.submitValidationMessage[language]
                  // }
                  returnToFeedbackPopconfirmMesage={
                    lables &&
                      lables.returnToFeedbackPopconfirmMesage &&
                      lables.returnToFeedbackPopconfirmMesage[language]
                      ? lables.returnToFeedbackPopconfirmMesage[language]
                      : 'Are you sure to return to feedback page?'
                  }
                  userFeedback={userFeedback}
                  // learnMore={lables.learnMore && lables.learnMore[language]}
                  // thanksMessage={
                  //   lables.thanksForAditionalsDetailsMessage
                  //     ? lables.thanksForAditionalsDetailsMessage[language]
                  //     : 'Thank you for the additional details'
                  // }
                />
              ) : null}
              {lables && (
                <FooterTemplate
                  powered={lables.powered ? lables.powered[language] : 'Powered by'}
                  copyright={
                    lables.copyright ? lables.copyright[language] : 'Copyright'
                  }
                  footerUrl={footerUrl ? footerUrl : ''}
                  companyName={
                    !showNearestLocationNotFound && footerCompanyName
                      ? footerCompanyName
                      : companyNameBrandLandingPage
                        ? companyNameBrandLandingPage
                        : pageInfo.title
                  }
                  feedbakType={feedbackType}
                />
              )}
            </div>
          )}
        </>} />
      </Switch>
    </div>
  );
};

export default App;
